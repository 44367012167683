import React, { Fragment } from 'react'

// import PropTypes from 'prop-types'


import classes from './Modal.module.css'


const Modal = ({title, isOpen, onCancel, onSubmit, children}) =>{


    return (
        <Fragment>
        {
            isOpen &&
            <div className={classes.modalOverlay}>
            <div className={classes.modalWindow}>
                <div className={classes.modalHeader}>
            <div className={classes.modalTitle}>{title} <a href="#" onClick={onCancel}> <i className="fa fa-times" aria-hidden="true"></i></a></div>
            
                </div>
                <div className={classes.modalBody}>
                    {children}
                </div>
                <div className={classes.modalFooter}>
                   
                </div>
            </div>
        </div>
        }
       
        </Fragment>
    )
}

// Modal.PropTypes = {
//     title: PropTypes.string,
//     isOpen: PropTypes.bool,
//     onCancel: PropTypes.func,
//     onSubmit: PropTypes.func,
//     children: PropTypes.node
// }

Modal.defaultProps = {
    title: 'Modal Title',
    isOpen: false,
    onCancel: ()=>{},
    onSubmit: () =>{},
    children: null
}



export default Modal