import Axios from 'axios'


export default function getAds(userUrl){

return async dispatch => {
    const token = localStorage.getItem('token');
    let fileData = new FormData();
    fileData.append('token', token)
const response = await Axios.get(userUrl)

console.log(response.data)


dispatch(successGet(response.data.ads))
}

}




export function successGet(ads){
    return {
        type: 'SUCCESS_GET_ADS',
        ads
    }
}