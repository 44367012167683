import React, { useState } from 'react'
import classes from './Header.module.css'
import Logo from '../../logo.png'
import {NavLink, Link} from 'react-router-dom'
import { connect } from 'react-redux'
import pay from '../../store/actions/payment'
import Modal from '../UI/Modal/Modal'
import Input from '../UI/Input/Input'
const  Header = (props) => {

    
    const [show, modalShow] = useState(false);
    const [paySum, setPaySum] = useState(1500);
    const [tarifs, setTarifs] = useState({
    
        free: 'Бесплатный',
        pro: 'Старт',
        gold: 'Бизнес',
        max: 'Максимальный'
    
})
    const showPay = (e) => {
        e.preventDefault()
        modalShow(!show);
        console.log(show)
        }

    const payment = (e) => {
        e.preventDefault();
        paySum > 0 ? props.paymentNow(props.shopId, paySum) : alert('Введите сумму больше нуля');
    }
return(
    <div className={classes.Header}>
        <div className="container">
            <div className={classes.headerContainer} >
            <div className={classes.logoSection}>
            <img src={Logo} alt="Azato.ru"/>
            <span>Все автозапчасти города</span>
            </div>
            <div className={classes.navigationSection}>
            <nav>
                <ul>
                    <li><NavLink activeClassName={classes.active} to="/" exact>О магазине</NavLink> </li>
                    <li><NavLink activeClassName={classes.active}  to="/details">Запчасти</NavLink></li>
                    <li><NavLink activeClassName={classes.active} to="/requests">Заявки</NavLink></li>
                </ul>
                
                
            </nav>
            </div>
            <div className={classes.authSection}>
                <div className="shopInfo">
                    <div>Личный кабинет "<span>{props.shopName}</span>"</div>
                    <div>Тариф: {tarifs[props.status]}</div>
                    <div className={classes.headerBalance}>Баланс: {props.balance} руб. <Link to="/" onClick={showPay}>Пополнить</Link></div>
                   
                    </div>
                <div> <Link to='/logout' className={classes.exitLink}>Выход <i className="fa fa-sign-out" aria-hidden="true"></i></Link></div>
            </div>

            </div>
   
        </div>
        <Modal onCancel={showPay} onSubmit={payment}  isOpen={show} title="Пополнение баланса">
            <Input type="number" onChange={(e)=>setPaySum(e.target.value)} value={paySum} required placeholder="Введите сумму платежа" />
            <button onClick={payment} className={classes.payLink}>Оплатить</button>
        </Modal>
    </div>
)

}
function mapStateToProps(state){
return{
    shopName: state.auth.user,
    balance: state.auth.balance,
    shopId: state.auth.shopId,
    status: state.auth.status
}

}

function mapDispatchToProps(dispatch){
    return{
        paymentNow: (shopId, amount) =>{dispatch(pay(shopId, amount))},
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Header)
