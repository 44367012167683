import React from 'react'
import classes from './Select.module.css'

const Select = (props) =>{

    const htmlFor= `${props.label}- ${Math.label}`
    return (
        <div className={classes.select}>
            <label htmlFor={htmlFor}>{props.label}</label>
            <select
            name={props.name}
            id={htmlFor}
            value={props.value}
            onChange={props.onChange}
            >
                  <option value="Значение не выбрано">Выберите значение</option>
            {props.options.map((option, index)=>{
                return(
                    <option
                    value={option.value}
                    key={option.value + index}
                    >
                        {option.text}
                    </option>
                )
            })
            }

            </select>
        </div>
    )
}



export default Select