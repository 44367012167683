import React from 'react'
import classes from './Users.module.css'

const  Users = () => {
return(
    <div className={classes.Users}>
        <h1>Пользователи</h1>
    </div>
)

}

export default Users
