import React, {useState} from 'react'
import classes from './Readme.module.css'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Type1 from '../../../../img/readme/type1-1.PNG'
import Type12 from '../../../../img/readme/type1-2.PNG'
import Type13 from '../../../../img/readme/type1-3.PNG'
import Type2 from '../../../../img/readme/type2-1.PNG'
import Type21 from '../../../../img/readme/type2-2.PNG'
import Type22 from '../../../../img/readme/type2-3.PNG'
import Load1 from '../../../../img/readme/load1.PNG'
import Load2 from '../../../../img/readme/load2.PNG'
import Load3 from '../../../../img/readme/load3.PNG'
import Load4 from '../../../../img/readme/load4.PNG'
import Load5 from '../../../../img/readme/load5.PNG'
import Load6 from '../../../../img/readme/load6.PNG'
import Conv1 from '../../../../img/readme/conv1/conv1.PNG'
import Conv2 from '../../../../img/readme/conv1/conv2.PNG'
import Conv3 from '../../../../img/readme/conv1/conv3.PNG'
import Conv4 from '../../../../img/readme/conv1/conv4.PNG'
import Conv5 from '../../../../img/readme/conv1/conv5.PNG'
import Conv6 from '../../../../img/readme/conv1/conv6.PNG'
import Conv7 from '../../../../img/readme/conv1/conv7.PNG'
import Conv8 from '../../../../img/readme/conv1/conv8.PNG'
import Conv9 from '../../../../img/readme/conv1/conv9.PNG'
import Conv10 from '../../../../img/readme/conv1/conv10.PNG'
import Conv11 from '../../../../img/readme/conv1/conv11.PNG'
import Conv12 from '../../../../img/readme/conv1/conv12.PNG'
import Conv13 from '../../../../img/readme/conv1/conv13.PNG'
import Conv14 from '../../../../img/readme/conv2/conv14.PNG'
import Conv15 from '../../../../img/readme/conv2/conv15.PNG'
import Conv16 from '../../../../img/readme/conv2/conv16.PNG'
import Conv17 from '../../../../img/readme/conv2/conv17.PNG'
import Conv18 from '../../../../img/readme/conv2/conv18.PNG'
import Conv19 from '../../../../img/readme/conv2/conv19.PNG'
import Conv20 from '../../../../img/readme/conv2/conv20.PNG'
export const Readme = (props) =>{

    const images = [
        Type1,
        Type12,
        Type13,
        Type2,
        Type21,
        Type22,
        Conv1,
        Conv2,
        Conv3,
        Conv4,
        Conv5,
        Conv6,
        Conv7,
        Conv8,
        Conv9,
        Conv10,
        Conv11,
        Conv12,
        Conv13,
        Conv14,
        Conv15,
        Conv16,
        Conv17,
        Conv18,
        Conv19,
        Conv20,
        Load1,
        Load2,
        Load3,
        Load4,
        Load5,
        Load6
     ];

    const [imageIndex, setImageIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    
    return (
        <div className={classes.Readme}>
            <h1>Инструкция по конвертации и загрузки прайс-листа на сервис AZATO.RU</h1>
            <ol>
                <li>Экспортируйте ваш прайс-лист из программы учета, если вы не знаете, как это сделать – посмотрите в разделе «справка» вашей программы учета.</li>
                <li>Откройте полученный файл в Excel. Если у вас не установлена эта программа, вы можете открыть файл с помощью Excel-online сохранив файл на Яндекс Диск.</li>
                <li>Теперь нужно определить к какому типу относится ваш прайс-лист:</li>
                </ol>
                <p>Тип 1- наименование запчасти и марка(модель) автомобиля записаны в одном столбце:</p>
            
            <img alt="Изображение" src={Type1} onClick={() => {setImageIndex(0); setIsOpen(true)} }/>
            <img alt="Изображение" src={Type12} onClick={() => {setImageIndex(1); setIsOpen(true)}}/>
            <img alt="Изображение" src={Type13} onClick={() => {setImageIndex(2); setIsOpen(true)}}/>
                <p>Тип 2- наименование запчасти и марка(модель) автомобиля записаны в разных столбца:</p>
            <img alt="Изображение" src={Type2} onClick={() => {setImageIndex(3); setIsOpen(true)}}/>
            <img alt="Изображение" src={Type21} onClick={() => {setImageIndex(4); setIsOpen(true)}}/>
            <img alt="Изображение" src={Type22} onClick={() => {setImageIndex(5); setIsOpen(true)}}/>
            <p>4. Конвертация прайс-листа</p>
<p><strong>Для файла первого типа:</strong></p>

<p>a. Скачайте конвертер №1.</p>
<p>b. Откройте ваш прайс-лист в одной вкладке и скаченный конвертер №1 в другой</p>
<p>c. Скопируйте столбцы «наименование» и «цена» из своего файла. Для этого нужно выделить столбцы поставив курсор на строку букв столбцов, нажать ПКМ (правая кнопка мыши) и удерживая ее выбрать нужные столбцы, затем нажмите «ctrl» + «c»</p>
<img alt="Изображение" src={Conv1} onClick={() => {setImageIndex(6); setIsOpen(true)}}/>
<p>d. Вставьте скопированные данные в столбцы конвертера №1. Для этого перейдите на вкладку конвертер №1 в Excel, нажмите ПКМ (правая кнопка мыши) на ячейку А2 (если в вашей таблице есть заголовки, вместо А2 выделите ячейку А1) выделив ее, затем нажмите «ctrl» + «v» чтобы вставить скопированные данные.</p>
<img alt="Изображение"  src={Conv2} onClick={() => {setImageIndex(7); setIsOpen(true)}}/>
<img alt="Изображение"  src={Conv3} onClick={() => {setImageIndex(8); setIsOpen(true)}}/>
<p>e. Скопируйте столбцы с красными заголовками из сконвертированного файла. Для этого нужно выделить столбцы поставив курсор на строку букв столбцов, нажать ПКМ (правая кнопка мыши) и удерживая ее выбрать нужные столбцы, затем нажмите «ctrl» + «c».</p>
<img alt="Изображение"  src={Conv4} onClick={() => {setImageIndex(9); setIsOpen(true)}}/>
<img alt="Изображение"  src={Conv5} onClick={() => {setImageIndex(10); setIsOpen(true)}}/>
<p>f. Создайте новый пустой файл Excel и вставьте в него скопированные столбцы. Для этого перейдите на вкладку только что созданного нового файла нажмите ПКМ (правая кнопка мыши) на ячейку А1 выделив ее, затем нажмите «ctrl» + «v» чтобы вставить скопированные данные.</p>
<img alt="Изображение"  src={Conv6} onClick={() => {setImageIndex(11); setIsOpen(true)}}/>
<img alt="Изображение"  src={Conv7} onClick={() => {setImageIndex(12); setIsOpen(true)}}/>
<img alt="Изображение"  src={Conv8} onClick={() => {setImageIndex(13); setIsOpen(true)}}/>
<p><i>*Примечание*: если в вашем прайс-листе имеются дополнительные данные, находящиеся в отдельных столбцах (модель кузова; модель двигателя; год автомобиля; фотография) скопируйте эти столбцы и вставьте в соответствующие столбцы нового файла. Проверьте название заголовков столбцов- они должны совпадать с названиями и порядком столбцов файла примера. </i></p>
<img alt="Изображение"  src={Conv9} onClick={() => {setImageIndex(14); setIsOpen(true)}}/>
<p>g. Сохраните файл в формате «CSV». Для этого нажмите «Файл» выберете пункт «сохранить как», -затем введите имя сохраняемого файла и формат «CSV разделители запятые».</p>
<img alt="Изображение"  src={Conv10} onClick={() => {setImageIndex(15); setIsOpen(true)}}/>
<img  alt="Изображение" src={Conv11} onClick={() => {setImageIndex(16); setIsOpen(true)}}/>
<img alt="Изображение" src={Conv12} onClick={() => {setImageIndex(17); setIsOpen(true)}}/>
<img alt="Изображение" src={Conv13} onClick={() => {setImageIndex(18); setIsOpen(true)}}/>
<p><strong>Для файла второго типа:</strong></p>
<p>a. Скачайте конвертер №2.</p>
<p>b. Откройте ваш прайс-лист в одной вкладке и скаченный конвертер №2 в другой</p>
<p>c. Скопируйте столбцы из своего файла. Для этого нужно выделить столбцы поставив курсор на строку букв столбцов, нажать ПКМ (правая кнопка мыши) и удерживая ее выбрать нужные столбцы, затем нажмите «ctrl» + «c».</p>
<img alt="Изображение" src={Conv14} onClick={() => {setImageIndex(19); setIsOpen(true)}}/>
<img alt="Изображение" src={Conv15} onClick={() => {setImageIndex(20); setIsOpen(true)}}/>
<p>d. Вставьте скопированные данные на лист «загрузка» конвертера №2. Для этого перейдите на вкладку конвертер №2 в Excel, нажмите ПКМ (правая кнопка мыши) на ячейку А1 выделив ее, затем нажмите «ctrl» + «v» чтобы вставить скопированные данные.</p>
<img alt="Изображение" src={Conv16} onClick={() => {setImageIndex(21); setIsOpen(true)}}/>
<p>e. Переименуйте заголовки скопированных столбцов на листе «загрузка», в конвертере №2, как в файле примере. (порядок столбцов в вашей таблице не имеет значения)</p>
<img alt="Изображение" src={Conv9} onClick={() => {setImageIndex(14); setIsOpen(true)}}/>
<img alt="Изображение" src={Conv17} onClick={() => {setImageIndex(22); setIsOpen(true)}}/>
<img alt="Изображение" src={Conv18} onClick={() => {setImageIndex(23); setIsOpen(true)}}/>
<p> <i>*Примечание*: если в вашем файле нет каких-либо данных (фотография; кузов и т.п.) создавать столбцы с таким заголовком не обязательно, переименовать нужно только те столбцы, в которых у вас есть данные. </i></p>
<p>f. Перейдите на вкладку «Результат». Скопируйте столбцы с красными заголовками из сконвертированного файла (вкладка «результат»). Для этого нужно выделить столбцы поставив курсор на строку букв столбцов, нажать ПКМ (правая кнопка мыши) и удерживая ее выбрать нужные столбцы, затем нажмите «ctrl» + «c».</p>
<img alt="Изображение" src={Conv19} onClick={() => {setImageIndex(24); setIsOpen(true)}}/>
<p>g. Сохраните файл в формате «CSV». Для этого нажмите «Файл» выберете пункт «сохранить как», -затем введите имя сохраняемого файла и формат «CSV разделители запятые».</p>
<img alt="Изображение" src={Conv6} onClick={() => {setImageIndex(11); setIsOpen(true)}}/>
<img alt="Изображение" src={Conv7} onClick={() => {setImageIndex(12); setIsOpen(true)}}/>
<img alt="Изображение" src={Conv20} onClick={() => {setImageIndex(25); setIsOpen(true)}}/>
<p>h. Сохраните файл в формате «CSV». Для этого нажмите «Файл» выберете пункт «сохранить как», -затем введите имя сохраняемого файла и формат «CSV разделители запятые».</p>
<img alt="Изображение" src={Conv10} onClick={() => {setImageIndex(15); setIsOpen(true)}}/>
<img alt="Изображение" src={Conv11} onClick={() => {setImageIndex(16); setIsOpen(true)}}/>
<img alt="Изображение" src={Conv12} onClick={() => {setImageIndex(17); setIsOpen(true)}}/>
<img alt="Изображение" src={Conv13} onClick={() => {setImageIndex(18); setIsOpen(true)}}/>

<p>5. Загрузите сохраненный файл в своем личном кабинете на AZATO.RU. Для этого Перейдите на вкладку «Запчасти», нажмите на кнопку «выбрать файл». Выберите файл, который хотите загрузить, затем нажмите «загрузить объявления из файла».</p>
<img alt="Изображение" src={Load1} onClick={() => {setImageIndex(26); setIsOpen(true)}}/>
<img alt="Изображение" src={Load2} onClick={() => {setImageIndex(27); setIsOpen(true)}}/>
<img alt="Изображение" src={Load3} onClick={() => {setImageIndex(28); setIsOpen(true)}}/>
<p>6. Подождите пока ваши объявления загрузятся на в личный кабинет.</p>
<img alt="Изображение" src={Load4} onClick={() => {setImageIndex(29); setIsOpen(true)}}/>
<p>7. Активируйте добавленные объявления в личном кабинете. Для этого нажмите кнопку «групповые операции» и выберете пункт «активировать все объявления»</p>
<img alt="Изображение" src={Load5} onClick={() => {setImageIndex(30); setIsOpen(true)}}/>
<img alt="Изображение" src={Load6} onClick={() => {setImageIndex(31); setIsOpen(true)}}/>


{isOpen && (
      <Lightbox
        mainSrc={images[imageIndex]}
        nextSrc={images[(imageIndex + 1) % images.length]}
        prevSrc={images[(imageIndex + images.length - 1) % images.length]}
        onCloseRequest={() => setIsOpen(false)}
        onMovePrevRequest={() =>
           
            setImageIndex((imageIndex + images.length - 1) % images.length)
           
          }
          onMoveNextRequest={() =>
            setImageIndex((imageIndex + 1) % images.length)}
      />
    )}
        </div>
    )

}


