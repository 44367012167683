import { SUCCESS_GET_CARS, SUCCESS_GET_MODELS, SUCCESS_GET_YEAR, SUCCESS_GET_SERIES} from "../actions/actionTypes"

const initialState = {
cars:[],
models:[],
years: [],
series: []
}

export default function createAdsReducer(state = initialState, action){

    switch(action.type){
        case SUCCESS_GET_CARS:
            return{
          ...state, cars: action.cars
                   }
        case SUCCESS_GET_MODELS:
            return{
        ...state, models: action.models
                }
        case SUCCESS_GET_YEAR:
             return{
        ...state, years: action.years
                }
        case SUCCESS_GET_SERIES:
            return{
            ...state, series: action.series
            }
        default:
            return state
    }

}

