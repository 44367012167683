import React from 'react'
import classes from './Registration.module.css'
import Button from '../UI/Button/Button'
import Input from '../UI/Input/Input'
import validator from 'validator'
import { connect } from 'react-redux'
import {reg} from '../../store/actions/reg'
class Registration extends React.Component {

    state={
        isFormValid: false,
        registerResult:'Ваш пользователь успешно создан. Теперь Вы можете войти.',
        regFormControls: {
            email:{
                value: '',
                type: 'email',
                label: 'Email',
                errorMessage: 'Введите корректный email',
                valid: false,
                touched: false,
                validation: {
                    required: true,
                    email: true
                }
            },
            password: {
                value: '',
                type: 'password',
                label: 'Пароль',
                errorMessage: 'Введите корректный пароль',
                valid: false,
                touched: false,
                validation: {
                    required: true,
                    minLength: 6
                }
            },
            reqPassword: {
                value: '',
                type: 'password',
                label: 'Повторите пароль',
                errorMessage: 'Пароли не совпадают',
                valid: false,
                touched: false,
                validation: {
                    required: true,
                    password2: true
                }
            },
            phone: {
                value: '',
                type: 'phone',
                label: 'Введите номер телефона',
                errorMessage: 'Введите корректный номер телефона',
                valid: true,
                touched: true,
                validation: {
                    required: false,
                    maxLength: 11
                }
            },
            shopName: {
                value: '',
                type: 'text',
                label: 'Введите название вашего магазина',
                errorMessage: 'Введите корректное название',
                valid: true,
                touched: true,
                validation: {
                    required: false,
                    minLength: 2
                }
            }
        }
    }

    shopImage = React.createRef()

    registerHandler = () =>{

        this.props.reg(
            this.state.regFormControls.email.value,
            this.state.regFormControls.password.value,
            this.state.regFormControls.shopName.value,
            this.state.regFormControls.phone.value,
            this.shopImage.current.files[0]
        )

    }

    submitHandler = (e) =>{
    e.preventDefault();
    }

    validateControl(value, validation) {
        if(!validation) {
         return   true
        }

        let isValid = true

        if(validation.required){
            isValid = value.trim() !== '' && isValid
        }

        if(validation.email){
            isValid = validator.isEmail(value) && isValid
        }
        if(validation.password2){
            (value === this.state.regFormControls.password.value) && isValid ? isValid = true : isValid = false 

        }
        if(validation.minLength){
            isValid = value.length >= validation.minLength && isValid
        }
        if(validation.maxLength){
            isValid = value.length <= validation.maxLength && isValid
        }

        return isValid;
    }
    onChangeHandler =(event, controlName) =>{
        const regFormControls = {...this.state.regFormControls};
        const control = {...regFormControls[controlName]}

        control.value = event.target.value
        control.touched = true
        control.valid = this.validateControl(control.value, control.validation)
        regFormControls[controlName] = control

        let isFormValid = true

        Object.keys(regFormControls).forEach(name =>{
            isFormValid = regFormControls[name].valid && isFormValid
        })
        this.setState({
            regFormControls,
           isFormValid
        })
    }
    changeForm = () => {
        
        this.setState({
            formType: !this.state.formType
        })
    }
    renderInputs() {
        return Object.keys(this.state.regFormControls).map((controlName, index) =>{
            const control = this.state.regFormControls[controlName]
            return(
                <Input 
                    key={controlName + index}
                    type={control.type}
                    value={control.value}
                    valid={control.valid}
                    touched={control.touched}
                    label={control.label}
                    shouldValidate={!!control.validation}
                    errorMessage={control.errorMessage}
                    onChange={event=> this.onChangeHandler(event, controlName)}
                />
            )
        })

    }
    addImage = (e) => {
        console.log(this.shopImage.current.files[0])

    }
    render() {
        return (
            <div className={classes.Authorization}>
                <div>
                    <h1>Регистрация</h1>
                    <form onSubmit={this.submitHandler} className={classes.AuthForm}>
                      {this.renderInputs()}
                    <label>Добавьте Ваш логотип</label>
                     <input type="file" ref={this.shopImage}  onChange={this.addImage} />
                     <Button className={classes.regButton} type="primary" disabled={!this.state.isFormValid} onClick={this.registerHandler}>Регистрация магазина</Button>
                     {this.props.status ? '' : <p className={classes.okMessage}> {this.state.registerResult} </p>} 
                     </form>
                     
            
     

           
                </div>
         </div>
        )
    }
}
function mapStateToProps(state){
    return{
        status: state.reg.status
    }
    }
function mapDispatchToProps(dispatch){
    return{
        reg: (email, password, shopName, phone, file) =>{dispatch(reg(email, password, shopName, phone, file))}
    }
    }
    
    export default connect(mapStateToProps, mapDispatchToProps)(Registration)
