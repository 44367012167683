import React from 'react'
import classes from './Request.module.css'
import { connect } from 'react-redux'
import getRequests, { deleteRequest } from '../../store/actions/getRequest'

const Request = (props) =>{



 
    return(
        <div  className={classes.Request}>
        
            <div className={classes.RequestInfo}>     
    <p><b>Вопрос по объявлению:</b>  {props.ad_name}</p>
    <p><b>Дата поступления заявки:</b> {props.date} </p>
    <p><b>Имя заказчика: </b>{props.name}</p>
    <p><b>Вопрос: </b>{props.text}</p>
    <hr/>
    <p><b>Телефон: {props.phone} / Email: {props.email}</b></p>
   
   
                </div>
             
            </div>
    )


}
function mapStateToProps(state){
    return {
    userId: state.auth.id
    }
    }

function mapDispatchToProps(dispatch){
    return{

        deleteRequest: (url) => {dispatch(deleteRequest(url))},
        getRequests: (userUrl) => dispatch(getRequests(userUrl))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Request)


