import { REG_SUCCESS } from "../actions/actionTypes"



const initialState = {
    nickname: '',
    password: '',
    email: '',
    role: 1,
    name_shop: '',
    phone: '',
    image: '',
    status: true
}

export default function regReducer(state = initialState, action){

    switch (action.type){
        case REG_SUCCESS:
            return {
                ...state, nickname: null, password: null, email: null, role: 1, name_shop: null, phone: null, status: false, image: null
            }
        default:
            return state
    }
}