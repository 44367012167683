import { SUCCESS_GET_REQUESTS } from "../actions/actionTypes"


const initialState = {
requests: []
}


export default function getAdsReducer(state=initialState, action){
        switch(action.type){
                case SUCCESS_GET_REQUESTS:
                        return{
                        ...state, requests: action.requests
                        }
            default:
                return state
        }
}