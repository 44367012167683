import Axios from 'axios'


export default function changeAds(adsUrl, activeStatus, callback, userUrl){


return async dispatch => {
    const token = localStorage.getItem('token');
    let fileData = new FormData();
    fileData.append('active', activeStatus)
    fileData.append('token', token)
 
await Axios.put(adsUrl, fileData)

await callback(userUrl)

}

}


export function activeAllAds(token, userId, activeStatus, callback, userUrl){

    return async dispatch =>{
        let fileData = new FormData();
        fileData.append('active', activeStatus)
        fileData.append('token', token)
        const url = `https://azato.ru/todo/api/v1.0/ads_active/${userId}`
        try{
            await Axios.put(url, fileData)
            await callback(userUrl)
        }catch{

        }
      
    }
}

export function deactiveAllAds(token, userId, activeStatus, callback, userUrl){

    return async dispatch =>{
        let fileData = new FormData();
        fileData.append('active', activeStatus)
        fileData.append('token', token)
        const url = `https://azato.ru/todo/api/v1.0/ads_active/${userId}`
        try{
            await Axios.put(url, fileData)
            await callback(userUrl)
        }catch{

        }
      
    }
}




// export function successGet(ads){
//     return {
//         type: 'SUCCESS_GET_ADS',
//         ads
//     }
// }