import Axios from 'axios'

export default function pay(shopId, amount){
    return async dispatch => {
   try{
    const url = `https://azato.ru/todo/api/v1.0/pay`

    let fileData = new FormData();
    const token = localStorage.getItem('token');
    fileData.append('shop_id',shopId )
    fileData.append('amount', amount )
    fileData.append('token', token )
    const response = await Axios.post(url,fileData )
    console.log(response.data.Payment.PaymentURL)
    window.location.href = response.data.Payment.PaymentURL
   }catch(e){
    
   }
    }
}