import React, {Component} from 'react'
import classes from './Layout.module.css'

import Main from '../../Main/Main'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
class Layout extends Component{

    render() {
        return(
            <div className={classes.Layout}>
                <Header />
                <Main />
                <Footer/>
            </div>
        )
    }
}

export default Layout