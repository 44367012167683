import Axios from 'axios'


export default function getRequests(userId){

return async dispatch => {
const url = `https://azato.ru/todo/api/v1.0/shop/${userId}/orders`
console.log(userId)
try{
    const response = await Axios.get(url)

    console.log(response)
    
dispatch(successGet(response.data.orders))
}catch(e){
    console.log(e)
}



}

}



export function successGet(requests){
    return {
        type: 'SUCCESS_GET_REQUESTS',
        requests
    }
}

export function deleteRequest(url){

    return async dispatch =>{

        const response = await Axios.delete(url)
        console.log(response)
    }
}