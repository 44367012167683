import React from 'react';
import Ads from '../../Ads/Ads';
import classes from './Post.module.css'
const Posts = ({ posts, loading }) => {
  if (loading) {
    return <h2>Загрузка объявлений...</h2>;
  }

  return (
  <div>
      {posts.reverse().map(post => (
        <div key={post.url}>
        <Ads active={post.active} num={post.number}   mark={post.mark_auto} year={post.year_auto} model={post.model_auto} name={post.name} text={post.text}  price={post.price} image={post.image} series_auto={post.series_auto} modification_auto={post.modification_auto} url={post.url}/>
        </div>
      ))}
 </div>
  );
};

export default Posts;
