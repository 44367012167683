import React, { useEffect, useState  }  from 'react'
import classes from './Details.module.css'
import CreateAds from '../../../createAds/CreateAds'
import Ads from '../../../Ads/Ads'
import { connect } from 'react-redux'
import getAds from '../../../../store/actions/getAds'
import Modal from '../../../UI/Modal/Modal'
import Posts from '../../pagination/Posts';
import Pagination from '../../pagination/Pagination';
import Loader from '../../../UI/Loader/Loader'
import { deleteAllAds } from '../../../../store/actions/createAds'
import DropWindow from '../../../UI/DropWindow/DropWindow'
import { activeAllAds, deactiveAllAds } from '../../../../store/actions/changeAds'
const  Details = (props) => {
   //тестим пагинацию 
 
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [activeModalShow, setActiveModalShow] = useState(false);
  const [deactiveModalShow, setDeactiveModalShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(20);
  const [showDrop, setShowDrop] = useState(false)




  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = props.ads.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);





    // const [activeAds, setCount] = useState(true);

   const userUrl = props.userUrl

    useEffect( () => {
   
      const  fetchAds = async () =>{
        setLoading(true)
         await props.getAds(userUrl);
         setLoading(false)
        }
     
        fetchAds()
   },[]);
  
   const renderAds = () => {
            return props.ads.reverse().map((ads) => {
                return (
                    <div key={ads.url}>
                    <Ads active={ads.active} num={ads.vin_auto} mark={ads.mark_auto} year={ads.year_auto} model={ads.model_auto} name={ads.name} text={ads.text}  price={ads.price} image={ads.image} series_auto={ads.series_auto} modification_auto={ads.modification_auto} url={ads.url}/>
                    </div>
                )
            })
   }


   const deleteAllAds = async () =>{
    setLoading(true)
    const token = localStorage.getItem('token')  
     await props.deleteAllAds(
      token,
      props.userId,
      props.userUrl,
      props.getAds
     )
     setLoading(false)
    setModalShow(!modalShow)
   }

   const showDropFunc = () =>{
    setShowDrop(!showDrop)
    console.log(showDrop)
   }

   const activeAllAds = async()=>{
    setLoading(true)
    const token = localStorage.getItem('token')  
     await props.activeAllAds(
      token,
      props.userId,
      1,
      props.getAds,
      props.userUrl
     )
     setLoading(false)
     setActiveModalShow(!activeModalShow)
   }

   const deactiveAllAds = async()=>{
    setLoading(true)
    const token = localStorage.getItem('token')  
     await props.deactiveAllAds(
      token,
      props.userId,
      0,
      props.getAds,
      props.userUrl
     )
     setLoading(false)
     setDeactiveModalShow(!deactiveModalShow)
  }
return(
    
    <div className={classes.Details}>
        <h1>Запчасти</h1>
        {<CreateAds />}
        {/* {renderAds()} */}
        <DropWindow title="Групповые операции" isOpen={showDrop} onClick={showDropFunc}>
        <button className={classes.dropLink} onClick={()=>{setModalShow(!modalShow)}}>Удалить все объявления</button>
        <button className={classes.dropLink} onClick={()=>{setActiveModalShow(!activeModalShow)}}>Акивировать все объявления</button>
        <button className={classes.dropLink} onClick={()=>{setDeactiveModalShow(!deactiveModalShow)}}>Отключить все объявления</button>
        </DropWindow> 
      
        <Posts posts={currentPosts} loading={loading} />
      <Pagination
      currentPage={currentPage}
        postsPerPage={postsPerPage}
        totalPosts={props.ads.length}
        paginate={paginate}
      />
         <Modal onCancel={()=>{setModalShow(!modalShow)}} isOpen={modalShow} title="Удаление объявлений">
                <p>Вы уверены что хотите удалить Все свои объявления? Это действие нельзя быдет отменить.</p>
                {loading ? <Loader /> : <button onClick={deleteAllAds} className={classes.payLink}>Да</button>} 
                </Modal>

                <Modal onCancel={()=>{setActiveModalShow(!activeModalShow)}} isOpen={activeModalShow} title="Активация обьявлений">
                <p>Вы уверены что хотите активировать Все свои объявления?</p>
                {loading ? <Loader /> : <button onClick={activeAllAds} className={classes.payLink}>Да</button>} 
                </Modal>

                <Modal onCancel={()=>{setDeactiveModalShow(!deactiveModalShow)}} isOpen={deactiveModalShow} title="Отключение объявлений">
                <p>Вы уверены что хотите отключить показ всех объявлений?</p>
                {loading ? <Loader /> : <button onClick={deactiveAllAds} className={classes.payLink}>Да</button>} 
                </Modal>
      
        

        </div>
)

}

function mapStateToProps(state){
return {
userUrl: state.auth.adsUrl,
ads: state.getAds.ads,
userId: state.auth.id,
status: state.auth.status
}
}

function mapDispatchToProps(dispatch){
return {
    getAds: (userUrl) => dispatch(getAds(userUrl)),
    deleteAllAds: (token, userId, userUrl, callback) => dispatch(deleteAllAds(token, userId, userUrl, callback)),
    activeAllAds: (token, userId, active, callback, userUrl) => dispatch(activeAllAds(token, userId, active, callback, userUrl)),
    deactiveAllAds: (token, userId, active, callback, userUrl) => dispatch(deactiveAllAds(token, userId, active, callback, userUrl))
}
}

export default connect(mapStateToProps, mapDispatchToProps)(Details)
