import Axios from 'axios'

export default function createDetaile(name, text, mark_auto,model_auto,year_auto, series_auto, modification_auto, vin_auto,price,user_id, file, callback, userUrl){
    return async dispatch => {
   try{
    const token = localStorage.getItem('token');
    let fileData = new FormData();
    fileData.append('name', name)
    fileData.append('text', text)
    fileData.append('mark_auto', mark_auto)
    fileData.append('model_auto', model_auto)
    fileData.append('year_auto', year_auto)
    fileData.append('series_auto', series_auto)
    fileData.append('modification_auto', modification_auto)
    fileData.append('number', vin_auto)
    fileData.append('price', price)
    fileData.append('user_id', user_id)
    fileData.append('file', file)
    fileData.append('token', token)
    console.log(name, text, mark_auto,model_auto,year_auto, series_auto, modification_auto, vin_auto,price,user_id, file )
    const url = 'https://azato.ru/todo/api/v1.0/ads'
    
    await Axios.post(url, fileData)
    await callback(userUrl)
   }catch(e){
    console.log(e)
   }
    }
}

export function getCars(){

    return async dispatch => {
      
      const url="https://azato.ru/todo/api/v1.0/auto"
    const response = await Axios.get(url)
    
    console.log(response.data)
    dispatch(successGetCars(response.data.auto))
    }
    
    }

    export function successGetCars(cars){
        return {
            type: 'SUCCESS_GET_CARS',
            cars
        }
    }

    export function getModels(mark){
console.log(mark)
        return async dispatch => {
          const url=`https://azato.ru/todo/api/v1.0/auto/${mark}`
        const response = await Axios.get(url)
        
        console.log(response.data)
        dispatch(SuccessGetModels(response.data.model))
        }
        
        }
    
        export function SuccessGetModels(models){
            return {
                type: 'SUCCESS_GET_MODELS',
                models
            }
        }

        
    export function getYears(mark, model){
     
                return async dispatch => {
                  const url=`https://azato.ru/todo/api/v1.0/auto/${mark}/${model}`
                const response = await Axios.get(url)
                
                console.log(response.data)
                dispatch(SuccessGetYear(response.data.year))
                }
                
                }
    export function getSeries(mark, model, year){
     
            return async dispatch => {
            const url=`https://azato.ru/todo/api/v1.0/auto/${mark}/${model}/${year}`
            const response = await Axios.get(url)
             console.log(response.data.series)
            dispatch(SuccessGetSeries(response.data.series))
        }
                    
        }

        export function SuccessGetSeries(series){
            return {
                type: 'SUCCESS_GET_SERIES',
                series
            }
        }


   

            
                export function SuccessGetYear(years){
                    return {
                        type: 'SUCCESS_GET_YEAR',
                        years
                    }
                }

    export function deleteAds(url, callback, userUrl){

        return async dispatch =>{
            try{
                const token = localStorage.getItem('token');
                let fileData = new FormData();
                fileData.append('token', token)
                await Axios.delete(url, {data: {token: token}})
                await callback(userUrl)
            }catch{

            }
          
        }
    }
    export function deleteAllAds(token, userId, userUrl, callback){

        return async dispatch =>{
            const url = `https://azato.ru/todo/api/v1.0/ads_delete/${userId}`
            try{
                let fileData = new FormData();
                fileData.append('token', token)
                await Axios.delete(url,  {data: {token: token}})
                await callback(userUrl)
            }catch{

            }
          
        }
    }

   

    export function sendAds(id, file, callback, userUrl, successDownload){
        return async dispatch => {
            console.log(id , file)
            const token = localStorage.getItem('token');
            let fileData = new FormData();
            fileData.append('token', token)
            fileData.append('fileex', file)
            
            const url = 'https://azato.ru/todo/api/v1.0/import_csv_file'
           
                try{
                    const response = await Axios.post(url, fileData)
                    console.log(response)
                    let fileData2 = new FormData();
                    fileData2.append('file_name', response.data.file)
                    fileData2.append('user_id', id)
                    fileData2.append('token', token)
                    
                    successDownload()
                    await Axios.post('https://azato.ru/todo/api/v1.0/csv', fileData2)
                    callback(userUrl)
                }catch(e){
                    console.log(e)
                }
        
            }
    }