import Axios from "axios"

export function reg(email, password, shopName, phone, file){
 return async dispatch => {

    let fileData = new FormData();
    fileData.append('email', email)
    fileData.append('password', password)
    fileData.append('name_shop', shopName)
    fileData.append('phone', phone)
    fileData.append('role', 1)
    fileData.append('file', file)


  const url='https://azato.ru/todo/api/v1.0/users'

    console.log(fileData)

        const response = await Axios.post(url, fileData);

        console.log(response)

        localStorage.setItem('token', response.token)
        
        dispatch(regSuccess())
        // res.then((data) => {
        //     if (data.status === 201){
        //         this.setState({
        //             loginResult: 'Авторизация прошла успешно'
        //         })
        //     }else if(data.status === 200){
        //         this.setState({
        //             loginResult: 'Ошибка авторизации. Проверьте ваш логин или пароль.'
        //         })
        //     }
        // })
  
 }
}

export function regSuccess(){
    return {
        type: 'REG_SUCCESS'
    }
}