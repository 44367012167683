import React from 'react'
import classes from './Footer.module.css'
import Logo from '../../logo.png'
import {NavLink, Link} from 'react-router-dom'
const  Footer = () => {
return(
    <div className={classes.Footer}>
        <div className="container">
            <div className={classes.footerInner}>
            <div className={classes.FooterLogoSection}>
            <img src={Logo} alt="Azato.ru"/>
            <span>Все автозапчасти города</span>
            </div>
            <div className={classes.FooterNavigationSection}>
            <nav>
                <ul>
                    <li><NavLink to="/">Главная</NavLink> </li>
                    <li><NavLink to="/details">Запчасти</NavLink></li>
                    <li><NavLink to="/requests">Заявки</NavLink></li>
                </ul>
                
                
            </nav>
            </div>
            <div className={classes.FooterAuthSection}>
                <div><Link to='/logout' className={classes.exitLink}>Выход <i className="fa fa-sign-out" aria-hidden="true"></i></Link></div>
            </div>

            </div>
        </div>
        </div>
)

}

export default Footer
