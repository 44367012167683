import React, { Fragment } from 'react'

// import PropTypes from 'prop-types'


import classes from './DropWindow.module.css'


const DropWindow = ({title, isOpen, onClick, children}) =>{


    return (
        <Fragment>
        {<div className={classes.dropWindowWrapper}>
        <button className={classes.dropButton} onClick={onClick}>{title}
        {isOpen ? <div className={classes.drowWindow}> {children} </div> : ''}
        </button>
        </div>
        }
       
        </Fragment>
    )
}

// Modal.PropTypes = {
//     title: PropTypes.string,
//     isOpen: PropTypes.bool,
//     onCancel: PropTypes.func,
//     onSubmit: PropTypes.func,
//     children: PropTypes.node
// }

DropWindow.defaultProps = {
    title: 'Групповые операции',
    children: null
}



export default DropWindow