import React from 'react'
import classes from './Main.module.css'
import {Switch, Route, Redirect} from 'react-router-dom'

import About from './pages/About/About'
import Details from './pages/Details/Details'
import Requests from './pages/Requests/Requests'
import Users from './pages/Users/Users'
import { Readme } from './pages/Readme/Readme'
const  Main = () => {
return(
    <div className={classes.Main}>
        <div className="container">
        <Switch>
           
                    <Route path="/"  exact component={About} />
                    <Route path="/details"   component={Details}/>
                    <Route path="/requests"  component={Requests}/>
                    <Route path="/readme"  component={Readme}/>
                    <Route path="/users"  component={Users}/>
                    <Redirect exact to='/'/>
      </Switch>
        </div>

    </div>
)

}

export default Main
