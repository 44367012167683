import { SUCCESS_GET_ADS } from "../actions/actionTypes"


const initialState = {
ads: [], 
cars:[]
}


export default function getAdsReducer(state=initialState, action){
        switch(action.type){
                case SUCCESS_GET_ADS:
                        return{
                        ...state, ads: action.ads
                        }
            default:
                return state
        }
}