import React from 'react'
import classes from './Ads.module.css'
import { connect } from 'react-redux'
import { deleteAds } from '../../store/actions/createAds'
import getAds from '../../store/actions/getAds'
import changeAds from '../../store/actions/changeAds'

const Ads = (props) =>{

    const changeAd = async () =>{
        let activeStatus;
        props.active === 0 ? activeStatus = 1 :  activeStatus = 0 
       await props.changeAds(props.url, activeStatus,props.getAds,props.userUrl)

    }

    const deleteAd =  async ()=>{
      await props.deleteAds(props.url, props.getAds, props.userUrl)
     
        }
    return(
        <div  className={classes.Ads}>
            <div className={classes.AdsPhoto}>
            <img src={props.image} alt=""/>
            </div>
            <div className={classes.AdsInfo}>
    <h3>{props.name} на {props.mark} {props.model}</h3>
    <p>Номер детали:{props.num}</p>
    <p>Кузов/двигатель:{props.series_auto}</p>
    <p>Год выпуска автомобиля: {props.year}</p>
            <p>{props.text}</p>
                </div>
                <div className={classes.AdsMore}>
                    <div className={classes.activeBox}><span>Активность: </span>
                        <label className={classes.switch}>
  <input type="checkbox" onChange={changeAd} checked={Boolean(props.active)}/>
  <span className={classes.slider}></span>
            </label>
            </div>
    <h3>{props.price} руб.</h3>
    <button onClick={deleteAd}>Удалить объявление</button>
                </div>
            </div>
    )


}
function mapStateToProps(state){
    return {
    userUrl: state.auth.adsUrl
    }
    }

function mapDispatchToProps(dispatch){
    return{

        deleteAds: (url, callback, userUrl) => {dispatch(deleteAds(url, callback, userUrl))},
        getAds: (userUrl) => {dispatch(getAds(userUrl))},
        changeAds: (url, activeStatus, callback, userUrl) => {dispatch(changeAds(url, activeStatus, callback, userUrl))}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Ads)


