import {combineReducers} from 'redux'
import authReducer from './auth'
import regReducer from './reg'
import createAdsReducer from './createAds'
import getAdsReducer from './getAds'
import getRequests from './getRequests'


export default combineReducers({
    auth: authReducer,
    reg: regReducer,
    createAds: createAdsReducer,
    getAds: getAdsReducer,
    getRequests: getRequests
})