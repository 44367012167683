import React from 'react';
import './App.css';

import {Switch, Route, withRouter, Redirect} from 'react-router-dom'

import Authorization from './components/Authorization/Authorization';
import Layout from '../src/components/hoc/Layout/Layout'
import { connect } from 'react-redux';
import { autoLogin } from './store/actions/auth';
import Logout from './components/Logout/Logout'

class App extends React.Component {

  componentDidMount(){
    this.props.autoLogin(); 
    setInterval(()=>{
      this.props.autoLogin();
      console.log('ss')
    }, 5000)

  }
    
    render(){

      let routes= (
        <Switch>
           <Route path="/login" exact component={Authorization}/>
           <Redirect to='/login' />
  
        </Switch>
      )
  
      if(this.props.isAuthenticated){
        routes = (
          <Switch>
          <Route path="/logout"  component={Logout} />
          <Route path="/"  component={Layout}/>
          {/* <Redirect to='/' /> */}
        </Switch>
        )
        }
      return (
        <div className="App">
    {routes}
         
        </div>
      );
    }


}

function mapStateToProps(state){
  return{
     isAuthenticated: !!state.auth.token
  }
}

function mapDispatchToProps(dispatch){
return{
  autoLogin: () => dispatch(autoLogin())
}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

