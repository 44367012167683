import React from 'react'
import classes from './Authorization.module.css'
import Button from '../UI/Button/Button'
import Input from '../UI/Input/Input'
import validator from 'validator'
import Registration from '../Registration/Registration'
import { connect } from 'react-redux'
import { auth } from '../../store/actions/auth'
import logo from '../../logo.png'
class Authorization extends React.Component {

    state={
        isFormValid: false,
        formType: true,
        loginResult:'Вход не выполнен. Проверьте введенные данные.',
        AuthFormControls: {
            email:{
                value: '',
                type: 'email',
                label: 'Email',
                errorMessage: 'Введите корректный email',
                valid: false,
                touched: false,
                validation: {
                    required: true,
                    email: true
                }
            },
            password: {
                value: '',
                type: 'password',
                label: 'Пароль',
                errorMessage: 'Введите корректный пароль',
                valid: false,
                touched: false,
                validation: {
                    required: true,
                    minLength: 6
                }
            }
        }
    }
   
    loginHandler = async () =>{
        this.props.auth(
            this.state.AuthFormControls.email.value, 
            this.state.AuthFormControls.password.value
        )
 
    }

    registerHandler = () =>{

    }

    submitHandler = (e) =>{
    e.preventDefault();
    }

    validateControl(value, validation) {
        if(!validation) {
         return   true
        }

        let isValid = true

        if(validation.required){
            isValid = value.trim() !== '' && isValid
        }

        if(validation.email){
            isValid = validator.isEmail(value) && isValid
        }
        if(validation.minLength){
            isValid = value.length >= validation.minLength && isValid
        }

        return isValid;
    }
    onChangeHandler =(event, controlName) =>{
        const AuthFormControls = {...this.state.AuthFormControls};
        const control = {...AuthFormControls[controlName]}

        control.value = event.target.value
        control.touched = true
        control.valid = this.validateControl(control.value, control.validation)
        AuthFormControls[controlName] = control

        let isFormValid = true

        Object.keys(AuthFormControls).forEach(name =>{
            isFormValid = AuthFormControls[name].valid && isFormValid
        })
        this.setState({
            AuthFormControls,
           isFormValid
        })
    }
    changeForm = () => {
        
        this.setState({
            formType: !this.state.formType
        })
    }

    clickHandler = (e)=>{
  
       
    }
    renderInputs() {
        return Object.keys(this.state.AuthFormControls).map((controlName, index) =>{
            const control = this.state.AuthFormControls[controlName]
            return(
                <Input 
                    key={controlName + index}
                    type={control.type}
                    value={control.value}
                    valid={control.valid}
                    touched={control.touched}
                    label={control.label}
                    shouldValidate={!!control.validation}
                    errorMessage={control.errorMessage}
                    onChange={event=> this.onChangeHandler(event, controlName)}
                />
            )
        })

    }
    componentDidMount = ()=>{
        this.validateControl();
    } 
    renderAuthForm = ()=>{
        return (
            <div>
            <h1 ref={this.clickHandler}>Авторизация</h1>
            <form onSubmit={this.submitHandler} className={classes.AuthForm}>
            {this.renderInputs()}
       
          <Button type="success" disabled={!this.state.isFormValid} onClick={this.loginHandler}>Войти</Button>
        <p className={classes.errorMessage}>{this.props.status ? '' : this.state.loginResult}</p> 
         </form>
      
           </div>
        )
    }
    render() {
        return (
            <div className={classes.Authorization}>
              
                <div  >
                <img className={classes.logo} src={logo} alt="Azato" />
                    {this.state.formType ? this.renderAuthForm() : <Registration />}
                    {this.state.formType  ?   
                    (<p className={classes.changeType}>Не зарегистрированы? <button onClick={this.changeForm}>Регистрация магазина</button></p> )
                    :
                    ( <p className={classes.changeType}>Уже зарегистрированы? <button onClick={this.changeForm}>Авторизация</button></p>)
            
                    }

           
                </div>
         </div>
        )
    }
}

function mapStateToProps(state){
return{
    status: state.auth.authStatus
}
}

function mapDispatchToProps(dispatch){
    return{
        auth: (email, password) =>{dispatch(auth(email, password))}
    }
    }
    
    export default connect(mapStateToProps, mapDispatchToProps)(Authorization)