import React from 'react';
import classes from './Post.module.css'
import { Link } from 'react-router-dom';
const Pagination = ({currentPage, postsPerPage, totalPosts, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
        <ul className={classes.pagination}>
        {pageNumbers.map(number => (
        currentPage === number ? 
          <li key={number} className="page-item"><Link to="!#"  className={classes.pageLink} style={{background: "#f63e3e", color: "white", fontWeight: 900}} onClick={(e) => {e.preventDefault(); paginate(number)}}>{number}</Link></li> : 
          <li key={number} className="page-item"><Link to="!#"  className={classes.pageLink} onClick={(e) =>{e.preventDefault(); paginate(number)}}>{number}</Link></li>
      ))}
      </ul>
    </nav>
  );
};

export default Pagination;
