import { AUTH_SUCCESS, AUTH_LOGOUT, AUTH_ERROR, SUCCESS_SHOP_CHANGE, GET_BALANCE } from "../actions/actionTypes"

const initialState = {
    user: null,
    token: null,
    phone: null,
    address: null,
    text: null,
    authStatus: true,
    id: null,
    adsUrl: null,
    imageUrl: '',
    shopId: null,
    balance: 0,
    status: 'free',
    error: null
}

export default function authReducer(state = initialState, action){

    switch(action.type) {
        case AUTH_SUCCESS:
            return {
                ...state, user: action.user, token: action.token,status: action.status, phone: action.phone, text: action.text, address: action.address, id: action.id, balance: action.balance, adsUrl: action.adsUrl, imageUrl: action.imageUrl, shopId: action.shopId
            }
        case AUTH_LOGOUT:
                return{
                    ...state, token: null, user: null, phone: null, address: null, text: null
                }
        case AUTH_ERROR:
            return {
                ...state, authStatus: false
        }
        case GET_BALANCE:
            return {
                ...state, balance: action.balances
            }
        case SUCCESS_SHOP_CHANGE:
        return {
            ...state,  user: action.shopName, phone:action.phone, email:action.email, text: action.text, imageUrl: action.file, balance: action.balance, status: action.status, error: action.error
        }
        default:
            return state
    }
}