import React from 'react'
import classes from './About.module.css'
import { connect } from 'react-redux'
import { changeShop, changeStatus } from '../../../../store/actions/auth'
import Input from '../../../UI/Input/Input'
import Modal from '../../../UI/Modal/Modal'
import Loader from '../../../UI/Loader/Loader'
class About extends React.Component {
    state={
        shopFormControls: {
            name:{
                value: this.props.shopName,
                type: 'text',
                label: 'Название магазина:',
                valid: true,
                touched: false,
                validation: {
                    required: false
                }
            },
            phone: {
                value: this.props.phone,
                type: 'text',
                label: 'Номер телефона:',
                valid: true,
                touched: false,
                validation: {
                    required: false
                }
            },
            address: {
                value:  this.props.address,
                type: 'text',
                label: 'Адрес магазина:',
                valid: true,
                touched: true,
                validation: {
                    required: false,
                    minLength: 6
                }
            }
        },
        about: this.props.text,
        status: this.props.status,
        timeStatus: this.props.status,
        modalShow: false,
        loader: false,
        tarifs:{
            free: 'Бесплатный',
            pro: 'Старт',
            gold: 'Бизнес',
            max: 'Максимальный'
        }
    }
    shopImage = React.createRef();
    onChangeHandler =(event, controlName) =>{
        const shopFormControls = {...this.state.shopFormControls};
        const control = {...shopFormControls[controlName]}

        control.value = event.target.value
        control.touched = true
        shopFormControls[controlName] = control

        let isFormValid = true

        Object.keys(shopFormControls).forEach(name =>{
            isFormValid = shopFormControls[name].valid && isFormValid
        })
        this.setState({
            shopFormControls,
            isFormValid
        })
    }
    componentDidMount(){
        setInterval(()=>{
            console.log('ss')
          }, 5000)
    }
    changeHandler = async() =>{
        this.setState({loader: true})
        await this.props.changeShop(
            this.state.shopFormControls.name.value,
            this.state.about,
            this.state.shopFormControls.phone.value, 
            this.state.shopFormControls.address.value,
            this.shopImage.current.files[0],
            this.props.token
        )
        this.setState({loader: false})
    }
  
    submitHandler = (e) =>{
    e.preventDefault();
    }
    changeForm = () => {
        
        this.setState({
            formType: !this.state.formType
        })
    }
    renderInputs() {
        return Object.keys(this.state.shopFormControls).map((controlName, index) =>{
            const control = this.state.shopFormControls[controlName]
            return(
                <Input 
                    key={controlName + index}
                    type={control.type}
                    value={control.value}
                    valid={control.valid}
                    touched={control.touched}
                    label={control.label}
                    shouldValidate={!!control.validation}
                    errorMessage={control.errorMessage}
                    onChange={event=> this.onChangeHandler(event, controlName)}
                />
            )
        })

    }
    addImage = (e) => {
        console.log(this.shopImage.current.files[0])

    }
    onChangeAbout = (e) => {
      this.setState({about: e.target.value})
      console.log(this.state.about)

    }
    showChangeTarif = (e) =>{
        e.preventDefault();
        let currentState = !this.state.modalShow;
        this.setState({modalShow: currentState})
        }

     onChangeStatus = async(e) => {
        e.preventDefault();
        this.setState({loader: true})
        await this.props.changeStatus(
            this.props.token,
            this.state.timeStatus
        )
        this.setState({loader: false})
      
     
    }

     changeStateStatus = (e) => {
        this.setState({timeStatus: e.target.value})
        console.log(this.state.timeStatus)
    }
    render(){
       

        return(
            <div className={classes.Footer}>
                <h1>О Магазине</h1>
                <div>
                <form className={classes.shopAbout} onSubmit={this.submitHandler}>
                    <div className={classes.infoTopBlock}>
                    <div className={classes.leftInfo}>
                    <p className={classes.label}>Изображение магазина: </p>
                <img src={this.props.imageUrl} alt="" className={classes.imageLogo}/>
                <p>Изменить изображение:</p>
                <input type="file" ref={this.shopImage}  onChange={this.addImage} />
                    </div>
                    <div className={classes.rightInfo}>
                    {this.renderInputs()}
                    </div>
                    </div>
                 
               
                <p className={classes.label}>Описание:</p>
                <textarea className={classes.textarea} value={this.state.about} wrap="hard" cols="100" rows="15" onChange={event=> this.onChangeAbout(event)}></textarea>
                
                <div className={classes.statusBlock}>
                <p>Статус пользователя сервиса: <span>{this.props.status === 'free' ? `Бесплатное использование` : 'Премиум'}</span></p>
                <p><span>{this.state.tarifs[this.props.status]} <i className="fa fa-suitcase" aria-hidden="true"></i></span></p>
                <p><a href="#" onClick={this.showChangeTarif}> Изменить тариф </a></p>
            
                </div>
                
                {this.state.loader ? <Loader /> : <input type="submit" onClick={this.changeHandler} value="Сохранить"/>}
                </form> 
                </div>
                <Modal onCancel={this.showChangeTarif} onSubmit={this.onChangeStatus} isOpen={this.state.modalShow} title="Изменение тарифа">
                <p>Выберите подходящий Вам тариф и нажмите "Применить"</p>
                <div className={classes.checkBoxes}>
                {/* <p><input name="dzen" type="radio" value="free" onClick={this.changeStateStatus}/>Бесплатный (5 объявлений)</p> */}
                <p><input name="dzen" type="radio" value="pro" onClick={this.changeStateStatus}/>Старт - 4999р./мес. (до 5000 объявлений)</p>
                <p><input name="dzen" type="radio" value="gold" onClick={this.changeStateStatus}/>Бизнес - 6999р./мес. (до 10000 объявлений)</p>
                <p><input name="dzen" type="radio" value="max" onClick={this.changeStateStatus}/>Про - <s>9999р./мес.</s> (до 15000 объявлений) - временно бесплатно</p>
                </div>

                <strong>Ваш текущий тариф: {this.state.tarifs[this.props.status]}  </strong>
                {this.state.loader ? <Loader /> : ''}
               {this.props.error ?  <p className={classes.error}>{this.props.error} </p>: ''}
                <button onClick={this.onChangeStatus} className={classes.payLink}>Изменить тариф</button>
                </Modal>
            </div>
        )
        
        }
      
    
    }

    function mapDispatchToProps(dispatch){
        return {
            changeShop: (shopName, aboutShop, phone, adress, file, token) => dispatch(changeShop(shopName, aboutShop, phone, adress, file, token)),
            changeStatus: (token, newStatus) => dispatch(changeStatus(token, newStatus))
       
        }
        }
        
        function mapStateToProps(state){
            return{
                shopName: state.auth.user,
                phone: state.auth.phone, 
                token: state.auth.token,
                address: state.auth.address,
                text: state.auth.text,
                imageUrl: state.auth.imageUrl,
                status: state.auth.status,
                error: state.auth.error
            }
    }
    
export default connect(mapStateToProps, mapDispatchToProps)(About)
