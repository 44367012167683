export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const REG_SUCCESS = 'REG_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const SUCCESS_SHOP_CHANGE = 'SUCCESS_SHOP_CHANGE'
export const SUCCESS_GET_ADS = 'SUCCESS_GET_ADS'
export const SUCCESS_GET_CARS = 'SUCCESS_GET_CARS'
export const SUCCESS_GET_MODELS = 'SUCCESS_GET_MODELS'
export const SUCCESS_GET_YEAR = 'SUCCESS_GET_YEAR'
export const SUCCESS_GET_SERIES = 'SUCCESS_GET_SERIES'
export const SUCCESS_GET_MODIFICATION = 'SUCCESS_GET_MODIFICATION'
export const SUCCESS_GET_REQUESTS = 'SUCCESS_GET_REQUESTS'
export const GET_BALANCE = 'GET_BALANCE'
