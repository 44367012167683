import React, { useEffect}  from 'react'
import classes from './Requests.module.css'
import { connect } from 'react-redux'
import getRequests from '../../../../store/actions/getRequest'
import Request from '../../../Requests/Request'
const  Requests = (props) => {



    const userid = props.userId
    useEffect(() => {
        props.getRequests(userid)
   },[]);

   const renderRequests = () => {
    return props.requests.reverse().map((request) => {
        return (
            <div key={request.id}>
            <Request url={request.url} ad_name={request.ad_name} email={request.email} phone={request.phone} text={request.text} name={request.name}  date={request.date_create} />
            </div>
        )
    })
}

return(
    <div className={classes.Requests}>
        <h1>Заявки</h1>
        { props.requests.length > 0 ? renderRequests() : <h4>Новых заявок не найдено</h4>        }
     
    </div>
)

}

function mapStateToProps(state){
    return {
    userId: state.auth.shopId,
    requests: state.getRequests.requests
    }
    }
    
    function mapDispatchToProps(dispatch){
    return {
        getRequests: (userUrl) => dispatch(getRequests(userUrl))
    }
    }
    
    export default connect(mapStateToProps, mapDispatchToProps)(Requests)
    
