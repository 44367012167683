import Axios from "axios"
import { AUTH_SUCCESS, AUTH_LOGOUT , GET_BALANCE} from "./actionTypes"
import jwtDecode from 'jwt-decode'

export function auth(email, password){
 return async dispatch => {
    const userData = {
        email, password
    }
    const url='https://azato.ru/todo/api/v1.0/auth'
  
    const response = await Axios.post(url, userData)
    const data = response.data;
    if(data.error){
        dispatch(authError())
    } else{
        let day = new Date();
           day.setDate(day.getDate() + 1);
        localStorage.setItem('token', data.token) 
        localStorage.setItem('experationDate', +new Date() + (3600 * 1000));
        console.log(data)
        console.log(response)
        dispatch(authSuccess(data.token))
    }
 
 }
}

export function authError(){
    return{
        type: 'AUTH_ERROR'
    }
}
export function authSuccess(token){
    console.log(jwtDecode(token))
    let user = jwtDecode(token).shop.name
    let phone = jwtDecode(token).shop.phone
    let text = jwtDecode(token).shop.text
    let address = jwtDecode(token).shop.address
    let id = jwtDecode(token).id
    let adsUrl = jwtDecode(token).ads
    let imageUrl = jwtDecode(token).shop.image
    let shopId = jwtDecode(token).shop.id
    let balance = jwtDecode(token).balance
    let status = jwtDecode(token).status
   return{
        type: AUTH_SUCCESS,
        token, 
        user,
        phone,
        text,
        address,
        id,
        adsUrl,
        imageUrl,
        shopId,
        balance,
        status
    }
}

export function getBalance(token){
    let balance = jwtDecode(token).balance;
    return {
        type: GET_BALANCE,
        balance
    }
}

export function autoLogin(){
    return async dispatch =>{
        const blockTime = localStorage.getItem('experationDate');
        console.log(blockTime)
        if (blockTime !== null) {
               if (+new Date() >= parseInt(blockTime)) {
      
            localStorage.removeItem('experationDate');
            localStorage.removeItem('token');
          } 
        }
        
        const token = localStorage.getItem('token')    
        if(!token || token === 'undefined'){
            dispatch(logout())
        } else{
            let fileData = new FormData();
            fileData.append('token', token)
            const url= jwtDecode(token).url
                const response = await Axios.put(url, fileData);
                 let data = response.data;
                 let newToken = data.token
                 localStorage.setItem('token', newToken)     
            dispatch(authSuccess(token))
     
        }
    }
}

export function logout(){
    localStorage.removeItem('token')
    return {
        type: AUTH_LOGOUT
    }
}


export function changeShop(name_shop, text_shop , phone, address,file, token){
    return async dispatch => {
   
       let fileData = new FormData();
       fileData.append('address', address)
       fileData.append('text_shop', text_shop)
       fileData.append('name_shop', name_shop)
       fileData.append('phone', phone)
       fileData.append('token', token)
       fileData.append('file', file)
       console.log(text_shop)
       const url= jwtDecode(token).url
   
           const response = await Axios.put(url, fileData);
            let data = response.data;
            let newToken = data.token
            localStorage.setItem('token', newToken)       
           console.log(data)
       dispatch(successChange(data))
    }
   }

   export function changeStatus(token, newStatus){
       return async dispatch =>{
        let fileData = new FormData();
        fileData.append('token', token)
        fileData.append('status', newStatus)
        const url= jwtDecode(token).url
        const response = await Axios.put(url, fileData);
        let data = response.data;
        let newToken = data.token
        localStorage.setItem('token', newToken)       
       console.log(data)
   dispatch(successChange(data))
       }
   }

   
   export function successChange(data){
        const shopName = data.shop.name
        const phone = data.shop.phone
        const address = data.shop.address
        const text = data.shop.text
        const file = data.shop.image
        const balance = data.balance
        
        const status= data.status
        const error = data.error.text
        console.log(shopName, balance)

            
       return{
           type: 'SUCCESS_SHOP_CHANGE',
           shopName, phone, address, text, file, balance, status, error
       }
   }