import React from 'react'
import Select from '../UI/Select/Select'
import Input from '../UI/Input/Input'

import classes from './createAds.module.css'
import { connect } from 'react-redux'
import createDetaile, { getYears, getSeries, sendAds } from '../../store/actions/createAds'
import {getCars, getModels} from '../../store/actions/createAds'
import getAds from '../../store/actions/getAds'
import Modal from '../UI/Modal/Modal'
import { NavLink } from 'react-router-dom'

import Converter1 from '../../docs/converter1.xlsx'
import Converter2 from '../../docs/converter2.xlsx'
import Pricelist from '../../docs/Pricelist.csv'
class createAds extends React.Component{

    file = React.createRef();
    
    state={
        showSuccessLoad: false,
        status: true,
        cars:[],
        yearAuto: '',
        auto:'',
        autoModel:'',
        autoSeries: '',
        autoModification: '',
        isFormValid: false,
        loading: false,
        adsFormControls: {
            detailName:{
                value: '',
                type: 'text',
                label: 'Название детали',
                errorMessage: 'Введите название детали',
                valid: false,
                touched: false,
                validation: {
                    required: true
                }
            },
            detailVin: {
                value: '',
                type: 'text',
                label: 'Номер детали',
                errorMessage: 'Номер детали',
                valid: true,
                touched: false,
                validation: {
                    required: false,
                }
            },
            detailPrice: {
                value: '',
                type: 'text',
                label: 'Цена (руб.)',
                errorMessage: 'Укажите цену',
                valid: false,
                touched: false,
                validation: {
                    required: true,
                }
            },
            
            detaileAbout: {
                value: '',
                type: 'text',
                label: 'Описание',
                errorMessage: 'Введите описание',
                valid: true,
                touched: false,
                validation: {
                    required: false,
                }
            }
        }
    }

  
    shopImage = React.createRef()
    adsList = React.createRef()
    componentDidMount(){
        this.props.getCars();
        this.getCar();
    }
    validateControl(value, validation) {
        if(!validation) {
         return   true
        }

        let isValid = true

        if(validation.required){
            isValid = value.trim() !== '' && isValid
        }

         return isValid;
    }
    renderInputs() {
        return Object.keys(this.state.adsFormControls).map((controlName, index) =>{
            const control = this.state.adsFormControls[controlName]
            return(
                <Input
                    key={controlName + index}
                    type={control.type}
                    value={control.value}
                    valid={control.valid}
                    touched={control.touched}
                    label={control.label}
                    shouldValidate={!!control.validation}
                    errorMessage={control.errorMessage}
                    onChange={event=> this.onChangeHandler(event, controlName)}
                />
            )
        })

    }
    onChangeHandler =(event, controlName) =>{
        const adsFormControls = {...this.state.adsFormControls};
        const control = {...adsFormControls[controlName]}

        control.value = event.target.value
        control.touched = true
        control.valid = this.validateControl(control.value, control.validation)
        adsFormControls[controlName] = control

        let isFormValid = true

        Object.keys(adsFormControls).forEach(name =>{
            isFormValid = adsFormControls[name].valid && isFormValid
        })
        this.setState({
            adsFormControls,
           isFormValid
        })
    }
    markChangeHandler = (e) =>{
        let state = e.target.name
        this.setState({
            [state]: e.target.value
        })
      
        this.props.getModels(e.target.value);
     }
     modelChangeHandler = (e) =>{
        const state = e.target.name

        this.setState({
            [state]: e.target.value
        })

        this.props.getYears(this.state.auto, e.target.value);
     }
     selectChangeHandler = (e) =>{
        const state = e.target.name

        this.setState({
            [state]: e.target.value
        })
        this.props.getSeries(this.state.auto, this.state.autoModel, e.target.value);
     }
     SeriesChangeHandler = (e) =>{
        const state = e.target.name

        this.setState({
            [state]: e.target.value
        })

      }
  

     stopSubmit = async (e) =>{
        e.preventDefault();
        console.log(this.state.isFormValid)
        this.setState({isFormValid: false})
        console.log(this.state.isFormValid)
        await this.props.createDetaile(
                    this.state.adsFormControls.detailName.value,
                    this.state.adsFormControls.detaileAbout.value,
                    this.state.auto,
                    this.state.autoModel,
                    this.state.yearAuto,
                    this.state.autoSeries,
                    this.state.autoModification,
                    this.state.adsFormControls.detailVin.value,
                    this.state.adsFormControls.detailPrice.value,
                    this.props.id,
                    this.shopImage.current.files[0],
                    this.props.getAds,
                    this.props.userUrl
                )
                this.setState({isFormValid: true})
   
    }

     getCar(){
             
   return this.props.cars.map((cars) => {
            return (
           {text: cars, value: cars}
            )
        })
  
}
    getModel(){
        return this.props.models.map((model) => {
            return (
           {text: model, value: model}
            )
        })
    }

    getYear(){
        return this.props.years.map((year) => {
            return (
           {text: year, value: year}
            )
        })
    }
    getSeries(){
        return this.props.series.map((series) => {
            return (
           {text: series, value: series}
            )
        })
    }
    successDownload = () => {
        this.setState({showSuccessLoad: true})
    }

    sendAdsList = async(e) => {
    e.preventDefault();
    this.setState({isFormValid: false})
   await this.props.sendAds(
        this.props.id,
        this.adsList.current.files[0],
        this.props.getAds,
        this.props.userUrl,
        this.successDownload

    )
    this.setState({isFormValid: true})
 
}

    onFileChange = e => { 
             this.setState({ file: e.target.files[0] });        
             console.log(e.target.files[0])
      }; 
      addImage = (e) => {
        console.log(this.shopImage.current.files[0])

    }
    render(){
    
        const auto = <Select 
        label="Марка автомобиля:"
        name="auto"
        value={this.state.auto}
        onChange={this.markChangeHandler}
        options={ this.getCar()}
        />
        const autoModel = <Select 
        label="Модель:"
        name="autoModel"
        value={this.state.autoModel}
        onChange={this.modelChangeHandler}
        options={ this.getModel() }
        />
        const yearAuto = <Select 
        label="Год автомобиля:"
        name="yearAuto"
        value={this.state.yearAuto}
        onChange={this.selectChangeHandler}
        options={this.getYear() }
        />
        const seriesAuto = <Select 
        label="Тип кузова:"
        name="autoSeries"
        value={this.state.autoSeries}
        onChange={this.SeriesChangeHandler}
        options={this.getSeries() }
        />
  
    
        return(
            <div>
            
                <div className={classes.addAdsBlock}>
                <form onSubmit={this.stopSubmit}>
                <div className={classes.avtoType}>
                         {auto}
            {autoModel}
            {yearAuto}
            {seriesAuto}
                </div>
                <div>
                {this.renderInputs()}
                <p>Добавить фотографию:</p>
                <input type="file" ref={this.shopImage}  onChange={this.addImage} />
                </div>
                <button className={classes.createButton} disabled={!this.state.isFormValid} onClick={this.stopSubmit}>Создать объявление</button>
                         </form>
                </div>
                <div className={classes.downloadAdsBlock}>
                {   this.props.status === 'free' ? <h3>Загрузка объявлений из документа не доступна на Вашем тарифе</h3> : <form onSubmit={this.stopSubmit}>
                <NavLink className={classes.infoLinks} to="/readme" exact>Инструкция по загрузке прайс-листа и работе с конвертерами</NavLink>
               
                <a download  className={classes.infoLinks} href={Pricelist}>Скачать пример заполнения прайс-листа</a>
                <a download  className={classes.infoLinks} href={Converter1}>Скачать конвертер прайс-листа 1</a>
                <a download  className={classes.infoLinks} href={Converter2}>Скачать конвертер прайс-листа 2</a> 
                <input type="file" ref={this.adsList}   />
                <button className={classes.createButton}  onClick={this.sendAdsList}>Загрузить объявления из файла</button>
                </form>}
                <Modal onCancel={()=>{this.setState({showSuccessLoad: false})}} isOpen={this.state.showSuccessLoad} title="Обьявления загружены на сервер">
                <p>Ваши объявления загружены. После обработки документа на сервере они появятся в Ваше личном кабинете. Это может занять до 10 минут, в зависимости от количества объявлений.</p>
                <button onClick={()=>{this.setState({showSuccessLoad: false})}} className={classes.payLink}>Да</button> 
                </Modal>
                </div>
         
       
            </div>
        )
    }

}

function mapStateToProps(state){
    return{
        id: state.auth.id,
        cars: state.createAds.cars,
        models: state.createAds.models,
        years: state.createAds.years,
        series: state.createAds.series,
        modification: state.createAds.modification,
        userUrl: state.auth.adsUrl,
        status: state.auth.status
    }
    }
    
    function mapDispatchToProps(dispatch){
        return{
            createDetaile: (name, text, mark_auto,model_auto,year_auto,series_auto, modification_auto, vin_auto,price,user_id, file, callback, userUrl) =>{dispatch(createDetaile(name, text, mark_auto,model_auto,year_auto,series_auto, modification_auto, vin_auto,price,user_id, file, callback, userUrl))},
            getCars: () =>{dispatch(getCars())},
            getModels: (model) =>{dispatch(getModels(model))},
            getYears: (mark, model) =>{dispatch(getYears(mark, model))},
            getSeries: (mark, model, year) =>{dispatch(getSeries(mark, model, year))},
            getAds: (userUrl) => dispatch(getAds(userUrl)),
            sendAds: (id, file, callback, url, successDownload) =>{dispatch(sendAds(id, file, callback, url, successDownload))}
            
        }
        }


export default connect(mapStateToProps, mapDispatchToProps)(createAds)